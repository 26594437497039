






































































import Component from 'vue-class-component';
import Vue from 'vue';
import { Round, RoundShort } from '@/interfaces/rounds/rounds';
import NewRound from '@/components/group/rounds/newRound.vue';
import SingleRound from '@/components/group/rounds/singleRound.vue';
import { Watch, Prop } from 'vue-property-decorator';
import { Route } from 'vue-router';
import FirebaseRealtime from '@/classes/firebaseRealtime';

@Component({
  components: { NewRound, SingleRound },
})
export default class Rounds extends Vue {
  @Prop()
  private roundTitle!: string;

  private loading = true;
  private isSaving = false;
  private currentRound: Round | null = null;
  private selectedRound = 0;
  private editingRound: Round | null = null;
  private error: string | null = null;

  get roundShorts(): RoundShort[] {
    const rounds = JSON.parse(JSON.stringify(this.$store.getters.roundShorts));
    rounds.sort((roundA, roundB) => {
      return roundA.date > roundB.date ? -1 : 1;
    });
    return rounds;
  }

  get editedRounds(): object {
    return this.$store.getters.roundState;
  }

  public mounted() {
    this.$store.dispatch('loadRoundShorts').finally(() => {
      this.loading = false;

      if (this.roundTitle) {
        this.processRoute(this.roundTitle);
      }
    });
  }

  public saveRound(round: Round) {
    this.isSaving = true;
    try {
      this.$store
        .dispatch('saveRound', round)
        .then((createdRound: Round) => {
          this.currentRound = createdRound;
          if (this.currentRound.id !== null) {
            this.signalRoundEditingStop(this.currentRound.id);
          }
          this.error = null;
        })
        .catch((error) => {
          console.log('error caught: ', error);
          this.error = error;
        })
        .finally(() => {
          this.isSaving = false;
          this.editingRound = null;
        });
    } catch (error) {
      console.log('error in try catch', error);
      this.error = error as string;
      this.isSaving = false;
    }
  }

  public editRound(roundId) {
    this.editingRound = this.currentRound;
    this.currentRound = null;
    this.signalRoundEditing(roundId);
  }

  public abortEdit() {
    if (this.editingRound !== null) {
      this.currentRound = this.editingRound;
      if (this.editingRound.id !== null) {
        this.signalRoundEditingStop(this.editingRound.id);
      }
      this.editingRound = null;
    }
  }

  @Watch('selectedRound')
  public roundSelected(newIndex, oldIndex) {
    // Do nothing if the same entry is clicked multiple times
    if (newIndex === oldIndex) {
      return;
    }
    if (newIndex > 0) {
      const title = encodeURI(this.roundShorts[newIndex - 1].title.toLowerCase().replace(/ /g, '_'));
      const date = this.roundShorts[newIndex - 1].date;
      this.$router.push('/spielabende/' + date + '-' + title);
    } else {
      if (this.currentRound !== null) {
        this.$router.push('/spielabende');
      }
    }
  }

  @Watch('$route')
  public onRouteChange(to: Route, from) {
    // In case of moving to blank route, just exit
    if (!to.params.roundTitle) {
      this.currentRound = null;
    } else {
      this.processRoute(to.params.roundTitle);
    }
  }

  @Watch('editedRounds')
  public onRoundsEdited(newValue: object, oldValue: object) {
    if (this.currentRound === null || this.currentRound.id === null) {
      return;
    }

    // Compare the two states to see if any entry is missing in new object
    const oldEditedValues = Object.keys(oldValue);
    const newEditedValues = Object.keys(newValue);

    // Quick case which should cover 95% of cases: 1 entry was edited and is now finished
    if (newEditedValues.length === 0 && oldEditedValues.length === 1) {
      // Check if we are currently on this round and if so, reload it
      const editedRoundId = oldEditedValues.pop();

      if (this.currentRound.id === editedRoundId) {
        this.loading = true;
        this.$store.dispatch('loadRound', editedRoundId).then((round) => {
          this.$store.commit('addMessage', 'Bearbeitung abgeschlossen. Spielabend neu geladen.');
          this.currentRound = round;
          this.loading = false;
        });
      }
    }
  }

  public formatDate(date: string) {
    const dateParts = date.split('-');
    return dateParts[2] + '.' + dateParts[1] + '.' + dateParts[0];
  }

  private processRoute(routeParam: string) {
    // Try to extract the date
    const date = routeParam.substring(0, 10);

    // Find the round by that date
    const wantedRound = this.roundShorts.find((round) => round.date === date);

    if (typeof wantedRound === 'undefined') {
      this.error = 'Kein Spielabend gefunden für ' + this.formatDate(date);
    } else {
      this.loading = true;
      const foundIndex = this.roundShorts.findIndex((round) => round.id === wantedRound.id);
      if (foundIndex + 1 !== this.selectedRound) {
        this.selectedRound = foundIndex + 1;
      }
      this.$store.dispatch('loadRound', wantedRound.id).then((round) => {
        this.currentRound = round;
        this.loading = false;
      });
    }
  }

  private signalRoundEditing(roundId: string) {
    FirebaseRealtime.flagRound(this.$store.getters.currentGroup, roundId, this.$store.getters.userData.name);
  }

  private signalRoundEditingStop(roundId: string) {
    FirebaseRealtime.unflagRound(this.$store.getters.currentGroup, roundId);
  }

  private isRoundEdited(roundId: string) {
    return typeof this.editedRounds[roundId] !== 'undefined';
  }

  private getRoundEditor(roundId: string) {
    return this.editedRounds[roundId];
  }
}
