














































































import Component from 'vue-class-component';
import Vue from 'vue';
import { Round } from '@/interfaces/rounds/rounds';
import TextEditor from '@/components/general/texteditor.vue';
import { Prop, Watch } from 'vue-property-decorator';

@Component({
  components: { TextEditor },
})
export default class NewRound extends Vue {
  @Prop({ default: null })
  private round!: Round | null;

  private newRoundContent: string = '';
  private newRoundTitle: string = '';
  private newRoundDate: string = '';

  private toggleMenu = false;

  private dateMenu = false;

  private notEmptyRule = [(value) => !!value || 'Benötigt.'];

  public mounted() {
    if (this.round !== null) {
      if (this.round.description !== null) {
        this.newRoundContent = this.round.description;
      }
      this.newRoundDate = this.round.date;
      this.newRoundTitle = this.round.title;
    }
  }

  private saveClick() {
    let hasError = false;
    if (!(this.$refs.title as HTMLFormElement).validate(true)) {
      hasError = true;
    }
    if (!(this.$refs.date as HTMLFormElement).validate(true)) {
      hasError = true;
    }

    if (hasError) {
      return;
    }

    const round: Round = {
      id: this.round !== null ? this.round.id : null,
      description: this.newRoundContent,
      plans: [],
      title: this.newRoundTitle,
      date: this.newRoundDate,
      createdBy: this.$store.getters.getUser.uid,
      createdAt: Math.round(new Date().getTime()),
      updatedBy: null,
      updatedAt: this.round !== null ? this.round.updatedAt : 0,
    };
    this.$emit('round:save', round);
  }

  private abort() {
    this.newRoundContent = '';
    this.newRoundDate = '';
    this.newRoundTitle = '';
    this.$emit('round:abort');
  }
}
