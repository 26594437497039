








































import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { Handout } from '@/interfaces/handouts/handouts';
import { Round } from '../../../interfaces/rounds/rounds';

@Component
export default class SingleRound extends Vue {
  @Prop()
  private round!: Round;

  @Prop({ default: true })
  private editable!: boolean;

  private roundSaving = false;
  private isDownloadingId: string | null = null;
  private error: Error | null = null;

  private preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
  private postHtml = '</body></html>';

  get handouts() {
    if (!this.round.handouts) {
      return [];
    }
    return this.round.handouts.filter((handout: Handout) => {
      if (handout.visibility === 'group') {
        return true;
      } else {
        return handout.visibleFor.includes(this.$store.getters.getUser.uid);
      }
    });
  }

  public editRound() {
    this.$emit('round:edit', this.round.id);
  }

  public formatDate(date: string) {
    const dateParts = date.split('-');
    return dateParts[2] + '.' + dateParts[1] + '.' + dateParts[0];
  }

  private downloadHandout(handout: Handout) {
    this.isDownloadingId = handout.id;
    this.$store
      .dispatch('loadHandoutUrl', handout)
      .then((url) => {
        window.open(url);
      })
      .catch((error) => {
        this.error = error;
      })
      .finally(() => {
        this.isDownloadingId = null;
      });
  }

  private exportToHtml() {
    const html = this.preHtml + this.round.description + this.postHtml;
    const blob = new Blob(['\ufeff', html], {
        type: 'application/msword',
    });
    const url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);
    // Specify file name
    const filename = this.round.title + '.doc';

    // Create download link element
    const downloadLink = document.createElement('a');

    document.body.appendChild(downloadLink);

    // Create a link to the file
    downloadLink.href = url;

    // Setting the file name
    downloadLink.download = filename;

    // triggering the function
    downloadLink.click();

    document.body.removeChild(downloadLink);
  }
}
